//
// Scrolltop
//

.svg-icon-jira {
  color: $primary;
}

.jira {
  display: flex;
  justify-content: center;
  align-items: center;
  width: get($scrolltop, size, desktop);
  height: get($scrolltop, size, desktop);
  position: fixed;
  bottom: get($scrolltop, bottom, desktop);
  right: get($scrolltop, right, desktop);
  cursor: pointer;
  z-index: 100;
  opacity: 70;
  @include border-radius($border-radius);

  .svg-icon {
    @include svg-icon-size(24px);
    @include svg-icon-color($white);
  }

  > i {
    font-size: 1.3rem;
    color: $white;
  }

  &:hover {
    > i {
      color: $white;
    }

    .svg-icon {
      @include svg-icon-color($white);
    }
  }

}
